// 3rd-party modules
import { useEffect, useState } from 'react';
import { Tooltip, message } from 'antd';

// project modules
import Button from '../../components/shared/button';
import ConfirmationPopup from '../../components/shared/popup/confirmationPopup';
import DataTable, { TableColumn } from "../../components/shared/list/dataTable";
import PostModal from '../../components/posts/postModal';
import { apiCall } from '../../helpers/apiHelper';

// apis
import * as PostApi from '../../apis/postApi';

// models
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from '../../models/dataSourceRequest';
import { Post } from "../../models/post";

export default function PostListPage() {
  const columns: TableColumn<Post>[] = [
    {
      title: 'Post #',
      dataIndex: 'postId',
      key: 'postId',
      dataType: 'number',
      width: 65
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      filters: [
        // { text: 'Post', value: 'post' },
        // { text: 'Page', value: 'page' },
        { text: 'Note', value: 'note' },
      ],
      width: 80,
      render: (text: any) => text.toUpperCase()
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      filterable: true,
      width: 100
    },
    // {
    //   title: 'Lang.',
    //   dataIndex: 'languageId',
    //   key: 'languageId',
    //   filterable: true,
    //   width: 80,
    //   render: (text: any) => text.toUpperCase()
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filterable: true,
      width: 80,
      render: (text: any) => text.toUpperCase()
    },
    {
      title: '',
      key: 'action',
      width: 60,
      render: (_, record: any) => (
        <group data-wrap='no' data-gap="5">
          <Tooltip title="Edit"><div className='button micro' onClick={() => onEditClick(record)}><icon>edit_square</icon></div></Tooltip>
          <Tooltip title="Delete"><div className='button micro' onClick={() => onDeleteClick(record)}><icon>delete</icon></div></Tooltip>
        </group>
      ),
    }
  ];
  const [currentPost, setCurrentPost] = useState<Post>(new Post());
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  // const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [showPostModal, setShowPostModal] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const abortController = new AbortController();

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    const request = { conditionList, sortList, pagination } as DataSourceRequest;

    setLoading(true);
    const response = await apiCall(PostApi.getPosts(request, abortSignal));
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setLoading(false);

    return response.success ? Post.toArrayOfClass(response.data?.value || []) : [];
  };

  /*
  const onAdvancedSearchApplyClick: SubmitHandler<any> = async (formData: any) =>{
  };

  const onAdvancedSearchCancelClick = () => {
  };
  */

  const onNewClick = () => {
    setCurrentPost(new Post());
    setShowPostModal(true);
  };

  const onEditClick = (post: Post) => {
    setCurrentPost({...post});
    setShowPostModal(true);
  };

  const onDeleteClick = (post: Post) => {
    setCurrentPost(post);
    setIsDeleteConfirmationPopupOpen(true);
  };

  const handleDeleteRequest = async () => {
    setLoading(true);
    const response = await apiCall(PostApi.deletePost(currentPost, abortController.signal));
    setLoading(false);

    if (response.success) {
      message.success(`Support Ticket deleted successfully.`);
      setIsDeleteConfirmationPopupOpen(false);
      setReload(true);
    }
  };

  const onPostSave = (e: Post) => {
    setReload(true);
  }

  return (
    <>
      <view className="table_cont" data-background="none">
        <group data-space="15"  data-gap="10" data-border="" data-background="highlight">
          <Button
            material
            icon="add"
            text="New"
            primary
            onClick={onNewClick}
          />
          <group data-position="right" data-width='auto' data-gap='10' data-align='center'>
            {/* <Button
              material
              icon="search"
              text="Advanced Search"
              outline
              onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
            />
            <separator vertical="" data-height="20"></separator> */}
            <Button
              material
              icon="refresh"
              text="Refresh"
              outline
              onClick={() => setReload(true)}
            />
          </group>
        </group>
        {/* {
          showAdvancedSearch &&
          <group data-space="15" data-gap="10" data-border="" data-background="highlight" data-align='center'>
            <separator vertical="" data-height="20"></separator>
            <Button
              material
              text="Apply"
              primary
              onClick={handleSubmit(onAdvancedSearchApplyClick)}
            />
            <Button
              material
              text="Cancel"
              outline
              onClick={onAdvancedSearchCancelClick}
            />
          </group>
        } */}
        <DataTable
          columns={columns}
          bordered={true}
          customPagination={true}
          reload={reload}
          dataPagination={true}
          getPageAsync={getPage}
          loading={loading}
          rowKey={record => `${record.postId}`}
          showTotal={true}
          size="small"
          totalRecords={totalRecords}
          onRow={(record) => ({
            onDoubleClick: () => onEditClick(record),
            style: { cursor: "pointer" },
          })}
        />
      </view>
      {!!showPostModal &&
        <PostModal open={showPostModal}
          closeOnSave={true}
          post={currentPost}
          onClose={() => setShowPostModal(false) }
          onSave={(e) => onPostSave(e) } />
      }
      {isDeleteConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Delete"
          positiveCallback={handleDeleteRequest}
          negativeCallback={() => {
            setIsDeleteConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
