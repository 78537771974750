// 3rd-party modules
import ReactQuill from "react-quill";
import { useEffect, useState } from "react";

// project modules
import DataTable, { TableColumn } from "../../components/shared/list/dataTable";
import { apiCall } from "../../helpers/apiHelper";

// apis
import * as CommonValueApi from '../../apis/commonValueApi';
import * as PostApi from '../../apis/postApi';

// models
import { Post } from "../../models/post";
import { CommonValue } from "../../models/commonValue";

// defines
const getNotes = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(PostApi.getNotes(abortSignal));

  return response.success ? Post.toArrayOfClass(response.data?.value || []) : [];
};

const getPostCategories = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(CommonValueApi.getPostCategories(abortSignal));

  return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
};

export default function ArchivePage() {
  const columns: TableColumn<any>[] = [
    {
      title: 'Notes',
      dataIndex: 'noteTitle',
      key: 'noteTitle',
      render: (text: any, record: any) => record['level'] === 3 ?
        <ReactQuill
          value={text}
          modules={{
            toolbar: [],
          }}
          onKeyDown={(e) => e.preventDefault()}
        /> :
      text
    }
  ];
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [categories, setCategories] = useState<CommonValue[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [notes, setNotes] = useState<Post[]>([]);
  const abortController = new AbortController();

  useEffect(() => {
    getPostCategoriesAsync();
  }, []);

  useEffect(() => {
    getNotesAsync();
  }, [categories]);

  useEffect(() =>
  {
    const categorizedNotes: any[] = [];

    for (const note of notes) {
      const record = categorizedNotes.find((n: any) => n.noteTitle === note.category);

      if (!record)
        categorizedNotes.push({ id: note.categoryId, level: 1, noteTitle: note.category, children: [{ id: `id-${note.postId}`, level: 2, noteTitle: note.title, children: [{ id: note.postId, level: 3, noteTitle: note.content }] }] });
      else
        record['children'].push({ id: `id-${note.postId}`, level: 2, noteTitle: note.title, children: [{ id: record['children'].length, level: 3, noteTitle: note.content }] });
    }

    setDataSource(categorizedNotes.map(x => {
      return {
        ...x,
        noteTitle: categories.find(y => y.value?.toLocaleLowerCase() === x.noteTitle.toLocaleLowerCase())?.valueCaption || x.noteTitle
      }
    }));
  }, [notes])

  const getPostCategoriesAsync = async () => {
    setLoadingCategories(true);
    setCategories(await getPostCategories(abortController.signal));
    setLoadingCategories(false);
  }

  const getNotesAsync = async () => {
    setLoading(true);
    setNotes(await getNotes(abortController.signal));
    setLoading(false);
  }

  return (
    <>
      <view className="table_cont" data-background="none">
        <DataTable
          columns={columns}
          bordered={true}
          dataSource={dataSource}
          rowKey={record => `${record.id}`}
          loading={loading}
          showTotal={true}
          size="small"
        />
      </view>
    </>
  );
}
