// 3rd-party modules
import Chart from "react-apexcharts";

// project modules
import Popup from "../popup/popup";

type Props = {
  options: any;
  series: any[];
  type?:
  | "line"
  | "area"
  | "bar"
  | "pie"
  | "donut"
  | "radialBar"
  | "scatter"
  | "bubble"
  | "heatmap"
  | "candlestick"
  | "boxPlot"
  | "radar"
  | "polarArea"
  | "rangeBar"
  | "rangeArea"
  | "treemap";
  title?: string;
  open: boolean;
  onClose?: () => void;
};

const CustomChartModal: React.FC<Props> = ({ options, series, type, title,  open, onClose }: Props) => {
  return (
    <>
      <Popup
        title={title}
        onClose={onClose}
        fixSize="large"
        noCommandbar={true}>
        <Chart
          options={options}
          series={series}
          type={type}
          width="100%"
          height="95%"
        />
      </Popup>
    </>
  );
};

export default CustomChartModal;
