// 3rd-party modules
import { v4 as uuidv4 } from "uuid";

export const trimStringProps = (object: any) => {
  for (let prop of Object.keys(object)) {
    if (typeof object[prop] === 'string')
      object[prop as keyof object] = (object[prop as keyof object] as String).trim();
  }

  return object;
}

export const generateKey = () => {
  return uuidv4();
};

/**
 *
 * @param object
 * @param includes : array, Use if you need to convert only a list of keys to snakecase. In case of seting both arrays, this one is prioritize.
 * @param excludes : array, Use if you need to convert all keys but these to snakecase. In case of seting both arrays, Include logic is prioritize.
 * @returns
 */
export const convertToSnakecase = (object: any, includes: any[] = [], excludes: any[] = []) => {
  for (let prop of Object.keys(object)) {
    if ((includes.length && includes.includes(prop)) || (!includes.length && !excludes.includes(prop))) {
      const newKey = prop.replace(/\.?([A-Z]+)/g, function (x,y){return "_" + y.toLowerCase()}).replace(/^_/, "");
      if (newKey !== prop) {
        object[newKey as keyof object] = object[prop as keyof object];
        delete object[prop as keyof object];
      }
    }
  }
  return object;
};

export const objectToFormData = (obj: any) => {
  const formData = new FormData();

  // Iterate over each key-value pair in the object
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      // If the value is an array, append each element separately
      if (Array.isArray(value)) {
        value.forEach((element) => {
          formData.append(key, element);
        });
      } else {
        // Otherwise, append the value directly
        formData.append(key, value);
      }
    }
  }

  return formData;
}

export const capitalizeFirstLetter = (input: string) => {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export const formatMoney = (amount: number, decimalCount = 0, decimal = '.', thousands = ',') => {
  if (amount === null)
    return "0";

  decimalCount = Math.abs(decimalCount)
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount

  const negativeSign = amount < 0 ? '-' : ''
  const i = parseInt(Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
  const j = (i.length > 3) ? i.length % 3 : 0

  return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - Number(i)).toFixed(decimalCount).slice(2) : '');
}

export const toKababCase = (input: string) => {
  return input.replace(/([a-z0–9])([A-Z])/g, "$1-$2").toLowerCase();
}

export const kababCaseToCamelCase = (input: string) => {
  return input.replace(/-./g, x=>x[1].toUpperCase());
}

export const b64toBlob = (b64Data: string, contentType: string ='', sliceSize: number = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}
